import { BaseCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  FramePaperType,
  FrameColorType,
  FrameOrientationType,
  FrameFormatType,
  FrameProduct,
  FramePrice,
  ProfessionalUserConfigurationFile,
  SupportType,
  PaperLaminationType,
  PhotoSupportType,
  SpacerType,
  SideThicknessType
} from './models';

class CachedLoadableCollection extends BaseCollection {
  loadByParams(params) {
    return this.fetch({
      data: params,
      headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    });
  }

  get loadParams() {
    return [];
  }
}

export class ProfessionalUserConfigurationFileCollection extends CachedLoadableCollection {
  get url() {
    return conf.settings.HOME_DECOR_USER_CONFIGURATION_FILE_URL;
  }

  get model() {
    return ProfessionalUserConfigurationFile;
  }

  async filterByParams(params) {
    return this.fetch({
      data: params,
      headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    });
  }
}

export class FrameProductCollection extends CachedLoadableCollection {
  get name() {
    return 'frameProducts';
  }

  get url() {
    return conf.settings.FRAME_PRODUCTS_URL;
  }

  get model() {
    return FrameProduct;
  }

  filterByParams(params) {
    return this.fetch({
      data: params,
      headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    });
  }
}

export class FramePriceCollection extends CachedLoadableCollection {
  get name() {
    return 'framePrices';
  }

  get url() {
    return conf.settings.FRAME_PRICES_URL;
  }

  get model() {
    return FramePrice;
  }
}

export class FramePaperTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'papers';
  }

  get url() {
    return conf.settings.FRAME_PAPERS_URL;
  }

  get model() {
    return FramePaperType;
  }

  get loadParams() {
    return ['category', 'format', 'softwareCode', 'frameMaterial', 'photoSupport'];
  }
}

export class FrameOrientationTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'orientations';
  }

  get url() {
    return conf.settings.FRAME_ORIENTATIONS_URL;
  }

  get model() {
    return FrameOrientationType;
  }

  get loadParams() {
    return ['category', 'softwareCode', 'frameMaterial'];
  }
}

export class FrameFormatTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'formats';
  }

  get url() {
    return conf.settings.FRAME_FORMATS_URL;
  }

  get model() {
    return FrameFormatType;
  }

  get loadParams() {
    return [
      'category',
      'orientation',
      'softwareCode',
      'frameMaterial',
      'commercialProduct',
      'per_page'
    ];
  }
}

export class FrameColorTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'frameColors';
  }

  get url() {
    return conf.settings.FRAME_COLORS_URL;
  }

  get model() {
    return FrameColorType;
  }

  get loadParams() {
    return ['category', 'format', 'softwareCode', 'frameMaterial', 'photoSupport'];
  }
}

export class SideThicknessTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'sideThicknessTypes';
  }

  get url() {
    return conf.settings.FRAME_SIDE_THICKNESS_TYPES_URL;
  }

  get model() {
    return SideThicknessType;
  }

  get loadParams() {
    return ['category', 'format', 'softwareCode', 'commercialProduct', 'photoSupport'];
  }
}

export class SupportTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'supportTypes';
  }

  get url() {
    return conf.settings.FRAME_SUPPORT_TYPES_URL;
  }

  get model() {
    return SupportType;
  }

  get loadParams() {
    return ['category', 'softwareCode', 'commercialProduct', 'photoSupport'];
  }
}

export class PaperLaminationTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'paperLaminationTypes';
  }

  get url() {
    return conf.settings.FRAME_PAPER_LAMINATION_TYPES_URL;
  }

  get model() {
    return PaperLaminationType;
  }

  get loadParams() {
    return ['category', 'softwareCode', 'commercialProduct', 'paper'];
  }
}

export class PhotoSupportTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'photoSupportTypes';
  }

  get url() {
    return conf.settings.FRAME_PHOTO_SUPPORT_TYPES_URL;
  }

  get model() {
    return PhotoSupportType;
  }

  get loadParams() {
    return ['category', 'softwareCode', 'commercialProduct'];
  }
}

export class SpacerTypesCollection extends CachedLoadableCollection {
  get getFirstDefault() {
    return true;
  }

  get name() {
    return 'spacerTypes';
  }

  get url() {
    return conf.settings.FRAME_SPACER_TYPES_URL;
  }

  get model() {
    return SpacerType;
  }

  get loadParams() {
    return ['category', 'softwareCode', 'commercialProduct', 'photoSupport'];
  }
}
