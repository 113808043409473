import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class ProfessionalUserConfigurationFile extends BaseModel {
  get urlRoot() {
    return conf.settings.HOME_DECOR_USER_CONFIGURATION_FILE_URL;
  }

  get id() {
    return this.get('id');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  set configurationId(value) {
    this.set('configurationId', value);
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get downloadUrl() {
    return this.get('url');
  }

  get type() {
    return this.get('type');
  }

  set type(value) {
    this.set('type', value);
  }

  get softwareCode() {
    return this.get('softwareCode');
  }

  set softwareCode(value) {
    this.set('softwareCode', value);
  }
}

export class HomeDecorDatasheet extends BaseModel {
  get urlRoot() {
    return conf.settings.API_BASE_URL + this.datasheetUrl;
  }

  get datasheetUrl() {
    return this.get('datasheetUrl');
  }

  get id() {
    return this.get('id');
  }

  get svgFileType() {
    return this.get('svgFileType');
  }

  get previewSvgFileType() {
    return this.get('previewSvgFileType');
  }

  get svgPreview() {
    return this.get('svgPreview');
  }

  get svgPreviewUrl() {
    return this.get('svgPreviewUrl');
  }

  get svgProduction() {
    return this.get('svgProduction');
  }

  get svgProductionUrl() {
    return this.get('svgProductionUrl');
  }

  get userImageFileType() {
    return this.get('userImageFileType');
  }
}

export class FrameProduct extends BaseModel {
  static get modelName() {
    return 'product';
  }

  get id() {
    return this.get('id');
  }

  get code() {
    return this.get('code');
  }

  get orientationType() {
    return this.get('orientation');
  }

  get frameColorType() {
    return this.get('frameColor');
  }

  get paperType() {
    return this.get('paper');
  }

  get passepartoutCode() {
    return this.get('passepartout');
  }

  get passepartoutColorType() {
    return this.get('passepartoutColor');
  }

  get formatType() {
    return this.get('format');
  }

  get kitType() {
    return this.get('kitType');
  }

  get frameThickness() {
    return this.get('frameThickness');
  }

  get frontDatasheet() {
    return this.get('frontDatasheet');
  }

  get frameType() {
    return this.get('frameType');
  }

  get frameMaterialType() {
    return this.get('frameMaterial');
  }

  get photoMaterialType() {
    return this.get('photoMaterial');
  }

  get supportType() {
    return this.get('supportType');
  }

  get supportMaterialType() {
    return this.get('supportMaterial');
  }

  get supportColorType() {
    return this.get('supportColor');
  }

  get paperLaminationType() {
    return this.get('paperLamination');
  }

  get mosaicType() {
    return this.get('mosaicType');
  }

  get sideColor() {
    return this.get('sideColor');
  }

  get packagingCode() {
    return this.get('packaging');
  }

  get category() {
    return this.get('category');
  }

  get previews() {
    return this.get('previews');
  }

  get accessory() {
    return this.get('accessory');
  }

  get sideThickness() {
    return this.get('sideThickness');
  }

  get spacer() {
    return this.get('spacer');
  }

  get photoSupport() {
    return this.get('photoSupport');
  }

  get commercialProduct() {
    return this.get('commercialProduct');
  }
}

export class FrameConfiguration extends BaseModel {
  // url

  getAbsoluteUrl() {
    return this.urlRoot + '/' + this.id;
  }

  get urlRoot() {
    return conf.settings.FRAME_CONFIGURATIONS_URL;
  }

  // properties

  get id() {
    return this.get('id');
  }

  get numericId() {
    return this.get('numericId');
  }

  get productType() {
    return this.get('productType');
  }

  get productName() {
    return this.get('productName');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get packagingId() {
    return this.get('packagingId');
  }

  set packagingId(value) {
    this.set('packagingId', value);
  }

  get paper() {
    return this.get('paper');
  }

  set paper(value) {
    this.set('paper', value);
  }

  get orientation() {
    return this.get('orientation');
  }

  set orientation(value) {
    this.set('orientation', value);
  }

  get format() {
    return this.get('format');
  }

  set format(value) {
    this.set('format', value);
  }

  get frameColor() {
    return this.get('frameColor');
  }

  set frameColor(value) {
    this.set('frameColor', value);
  }

  get frameMaterial() {
    return this.get('frameMaterial');
  }

  set frameMaterial(value) {
    this.set('frameMaterial', value);
  }

  get passepartout() {
    return this.get('passepartout');
  }

  set passepartout(value) {
    this.set('passepartout', value);
  }

  get frameThickness() {
    return this.get('frameThickness');
  }

  set frameThickness(value) {
    this.set('frameThickness', value);
  }

  get passepartoutColor() {
    return this.get('passepartoutColor');
  }

  set passepartoutColor(value) {
    this.set('passepartoutColor', value);
  }

  get frameType() {
    return this.get('frameType');
  }

  set frameType(value) {
    this.set('frameType', value);
  }

  get photoMaterial() {
    return this.get('photoMaterial');
  }

  set photoMaterial(value) {
    this.set('photoMaterial', value);
  }

  get supportType() {
    return this.get('supportType');
  }

  set supportType(value) {
    this.set('supportType', value);
  }

  get supportMaterial() {
    return this.get('supportMaterial');
  }

  set supportMaterial(value) {
    this.set('supportMaterial', value);
  }

  get supportColor() {
    return this.get('supportColor');
  }

  set supportColor(value) {
    this.set('supportColor', value);
  }

  get paperLamination() {
    return this.get('paperLamination');
  }

  set paperLamination(value) {
    this.set('paperLamination', value);
  }

  get mosaicType() {
    return this.get('mosaicType');
  }

  set mosaicType(value) {
    this.set('mosaicType', value);
  }

  get kitType() {
    return this.get('kitType');
  }

  set kitType(value) {
    this.set('kitType', value);
  }

  get sideColor() {
    return this.get('sideColor');
  }

  set sideColor(value) {
    this.set('sideColor', value);
  }

  get packaging() {
    return this.get('packaging');
  }

  set packaging(value) {
    this.set('packaging', value);
  }

  get publicUnitFormattedPrice() {
    return this.get('publicUnitFormattedPrice');
  }

  get previews() {
    return this.get('previews');
  }

  set previews(value) {
    this.set('previews', value);
  }

  get quantity() {
    return this.get('quantity');
  }

  set quantity(value) {
    this.set('quantity', value);
  }

  get category() {
    return this.get('category');
  }

  set category(value) {
    this.set('category', value);
  }

  get commercialProduct() {
    return this.get('commercialProduct');
  }

  set commercialProduct(value) {
    this.set('commercialProduct', value);
  }

  get photoSupport() {
    return this.get('photoSupport');
  }

  set photoSupport(value) {
    this.set('photoSupport', value);
  }

  get sideThickness() {
    return this.get('sideThickness');
  }

  set sideThickness(value) {
    this.set('sideThickness', value);
  }

  get accessory() {
    return this.get('accessory');
  }

  set accessory(value) {
    this.set('accessory', value);
  }

  get colorCorrectionCode() {
    return this.get('colorCorrectionCode');
  }

  set colorCorrectionCode(value) {
    this.set('colorCorrectionCode', value);
  }

  get softwareCode() {
    return this.get('softwareCode');
  }

  set softwareCode(value) {
    this.set('softwareCode', value);
  }

  get spacer() {
    return this.get('spacer');
  }

  set spacer(value) {
    this.set('spacer', value);
  }
}

export class FramePrice extends BaseModel {
  static get modelName() {
    return 'price';
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }

  get price() {
    return this.get('price');
  }
}

// /**------------------- lookups -------------------**/

export class FramePaperType extends BaseModel {
  static get modelName() {
    return 'paper';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class FrameColorType extends BaseModel {
  static get modelName() {
    return 'frameColor';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class FrameOrientationType extends BaseModel {
  static get modelName() {
    return 'orientation';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class FrameFormatType extends BaseModel {
  static get modelName() {
    return 'format';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get nameCm() {
    return this.get('nameCm');
  }

  get nameIn() {
    return this.get('nameIn');
  }

  get previews() {
    return this.get('previews');
  }
}

export class SupportType extends BaseModel {
  static get modelName() {
    return 'supportType';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class PaperLaminationType extends BaseModel {
  static get modelName() {
    return 'paperLamination';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class PhotoSupportType extends BaseModel {
  static get modelName() {
    return 'photoSupport';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}

export class SpacerType extends BaseModel {
  static get modelName() {
    return 'spacer';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }

  get description() {
    return this.get('description');
  }
}

export class SideThicknessType extends BaseModel {
  static get modelName() {
    return 'sideThickness';
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get previews() {
    return this.get('previews');
  }
}
