import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LazyLoadImage } from '../../core/components/lazyLoadImage';

export class FramePreview extends React.Component {
  static get propTypes() {
    return {
      product: PropTypes.object
    };
  }
  get imageUrl() {
    if (this.props.product.productItem.previews || this.props.product.previewUrl) {
      let imageUrl = this.props.product.previewUrl
        ? this.props.product.previewUrl
        : this.props.product.productItem.previews[0].url;
      return imageUrl ? imageUrl + '?width=800&height=800' : null;
    }
    return null;
  }

  render() {
    let imageUrl = this.imageUrl;

    let loadingClass = classNames('img-container', { 'loading-widget': !imageUrl });

    let height = null;
    let width = null;
    if (this.props.height) {
      height = this.props.height + 'px';
    }
    if (this.props.width) {
      width = this.props.width + 'px';
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={loadingClass} style={{ height, width }}>
            {imageUrl ? <LazyLoadImage className="img-responsive" src={imageUrl} /> : imageUrl}
          </div>
        </div>
      </div>
    );
  }
}
