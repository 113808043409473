import { BaseSelectionHomeDecorWidgetWithImages } from '../../core/components/baseSelectionHomeDecorWidgetWithImages';

export class PhotoSupportTypesWidget extends BaseSelectionHomeDecorWidgetWithImages {
  get name() {
    return 'photoSupport';
  }

  get label() {
    return this.i18n.gettext('photo supporto');
  }

  get showWidget() {
    this._showWidget = this.props.items && this.props.items.length > 1;
    return this._showWidget;
  }
}
