import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import {
  createProfessionalPreOrder,
  getPreOrder,
  GuiErrors,
  navigateTo
} from '../core/utils/index';
import Logger from '../core/logger';
import { BaseConfiguratorController } from '../core/controllers';
import { frameFunnelSteps } from '../core/utils/breadcrumb';
import { BaseLoadingView } from '../core/views';

import { FrameContentView } from './views';
import {
  FramePaperTypesCollection,
  FrameOrientationTypesCollection,
  FrameFormatTypesCollection,
  FrameColorTypesCollection,
  FrameProductCollection,
  FramePriceCollection,
  ProfessionalUserConfigurationFileCollection,
  SideThicknessTypesCollection,
  SupportTypesCollection,
  PaperLaminationTypesCollection,
  PhotoSupportTypesCollection,
  SpacerTypesCollection
} from './managers';
import { FrameConfiguration, HomeDecorDatasheet } from './models';

import { ProfessionalPreOrdersCollection } from '../core/managers';

import { cleanFrameConfigurator } from '../core/storage/cleaners';
import { FrameProjectStorage } from '../core/storage';
import { getProjectName } from '../projects/utils';
import { Project } from '../projects/models';
import EventTracker, { productCategoryInterface, productInterface } from '../core/eventTracker';

/*
 * Controller used to initialize a new matted prints configuration
 * */
export class FrameCreateController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init() {
    this.startInitialRendering();

    if (runtime.isClient) {
      // clean local forage data
      await cleanFrameConfigurator();

      //create frame prints configuration
      let frameConfiguration = await this.createFrameConfiguration();

      if (frameConfiguration) {
        navigateTo(this.request, this.response, 'frame:main', {}, true, {
          configurationId: frameConfiguration.id,
          packagingConfigurationId: frameConfiguration.packagingId
        });
      } else {
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile creare la configurazione.'),
          'Error detail: FrameCreateController init',
          null,
          null,
          this.redirectToHomepage.bind(this)
        );
      }
    }
  }

  async createFrameConfiguration() {
    let shopcode = null;
    if (this.request.customerUserIsAuthorized) {
      shopcode = this.request.customerUser.shopCode;
    }

    let frameInitialProductConfiguration = await this.getInitialProductConfiguration();

    if (frameInitialProductConfiguration) {
      let frameInitialConfiguration = {
        category: frameInitialProductConfiguration.category,
        format: frameInitialProductConfiguration.formatType,
        frameColor: frameInitialProductConfiguration.frameColorType,
        frameMaterial: frameInitialProductConfiguration.frameMaterialType,
        frameThickness: frameInitialProductConfiguration.frameThickness,
        frameType: frameInitialProductConfiguration.frameType,
        kitType: frameInitialProductConfiguration.kitType,
        mosaicType: frameInitialProductConfiguration.mosaicType,
        orientation: frameInitialProductConfiguration.orientationType,
        packaging: frameInitialProductConfiguration.packagingCode,
        paper: frameInitialProductConfiguration.paperType,
        paperLamination: frameInitialProductConfiguration.paperLaminationType,
        passepartout: frameInitialProductConfiguration.passepartoutCode,
        passepartoutColor: frameInitialProductConfiguration.passepartoutColorType,
        photoMaterial: frameInitialProductConfiguration.photoMaterialType,
        sideColor: frameInitialProductConfiguration.sideColor,
        supportColor: frameInitialProductConfiguration.supportColorType,
        supportMaterial: frameInitialProductConfiguration.supportMaterialType,
        supportType: frameInitialProductConfiguration.supportType,
        quantity: 1,
        shopCode: shopcode,
        commercialProduct: frameInitialProductConfiguration.commercialProduct,
        photoSupport: frameInitialProductConfiguration.photoSupport,
        sideThickness: frameInitialProductConfiguration.sideThickness,
        accessory: frameInitialProductConfiguration.accessory,
        spacer: frameInitialProductConfiguration.spacer
      };

      let frameConfiguration = new FrameConfiguration();
      try {
        frameConfiguration = await frameConfiguration.save(frameInitialConfiguration, {
          data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE },
          headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
        });

        Logger.info('Frame Configuration id ', frameConfiguration.id);
        return frameConfiguration;
      } catch (err) {
        Logger.error('FrameCreateController.createFrameConfiguration', {
          error: err
        });
        return null;
      }
    }
  }

  async getInitialProductConfiguration() {
    let params = {
      category: this.request.query.category,
      format: this.request.query.format,
      orientation: this.request.query.orientation,
      paper: this.request.query.paper,
      frameColor: this.request.query.frameColor,
      frameMaterial: this.request.query.frameMaterial,
      commercialProduct: this.request.query.commercialProduct,
      softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE,
      photoSupport: this.request.query.photoSupport,
      sideThickness: this.request.query.sideThickness,
      spacer: this.request.query.spacer,
      supportType: this.request.query.supportType,
      paperLamination: this.request.query.paperLamination,
      passepartout: this.request.query.passepartout ? this.request.query.passepartout : 'NO'
    };
    let frameProductConfiguration = new FrameProductCollection();
    try {
      frameProductConfiguration = await frameProductConfiguration.filterByParams(params);

      if (frameProductConfiguration.length === 0) {
        frameProductConfiguration = await frameProductConfiguration.filterByParams(
          conf.settings.FRAMES_DEFAULT_CONFIGURATION
        );
      }
      return frameProductConfiguration.first();
    } catch (err) {
      Logger.error('FrameCreateController.getInitialProductConfiguration', {
        error: err
      });
      return null;
    }
  }
}

/*
 * Controller used to restore a saved HomeDecor configuration
 * */
export class FrameRestoreProjectController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  async getPreOrders(projectId) {
    return await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);
  }

  async getFrameConfiguration(id) {
    return await new FrameConfiguration({ id: id }).fetch({
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE },
      headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    });
  }

  async redirectToFrameNew() {
    navigateTo(this.request, this.response, 'frame:new', {}, true);
  }

  async init(projectId) {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let frameConfigurationId = null;
      let packagingConfigurationId = null;
      let project = null;
      let preOrders = null;

      let nextState = this.request.query['next-url']
        ? this.request.query['next-url']
        : 'frame:main';

      try {
        [project, preOrders] = await Promise.all([
          this.getProject(projectId),
          this.getPreOrders(projectId)
        ]);
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'Il prodotto che stai cercando è stato eliminato. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToMattedPrintsNew.bind(this)
          );
          return;
        } else {
          Logger.error('FrameRestoreProjectController.init - failed to fetch project or preorder', {
            error: err,
            projectId: projectId,
            project: project,
            preOrders: preOrders
          });
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      if (project && preOrders) {
        try {
          preOrders.forEach((preOrder) => {
            if (preOrder.productType === conf.settings.FRAME_PRODUCT_TYPE) {
              frameConfigurationId = preOrder.configurationGuid;
            }
          });

          let frameConfiguration = await this.getFrameConfiguration(frameConfigurationId);
          packagingConfigurationId = frameConfiguration.packagingId;

          // set new configurator data
          await Promise.all([FrameProjectStorage.setName(project.name)]);
        } catch (err) {
          Logger.error('FrameRestoreProjectController.init - failed to get configuration id', {
            error: err,
            project: project,
            preOrders: preOrders
          });
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      navigateTo(this.request, this.response, nextState, {}, true, {
        configurationId: frameConfigurationId,
        packagingConfigurationId: packagingConfigurationId
      });
    }
  }
}

export class FrameController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get productType() {
    return 'HomeDecor';
  }

  get funnelStep() {
    return 1;
  }

  get funnelSteps() {
    return frameFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.product &&
      this.product.productConfiguration &&
      this.product.productConfiguration.productName
      ? this.product.productConfiguration.productName
      : this.i18n.gettext('HomeDecor');
  }

  async getProductConfiguration() {
    let params = this.getConfigurationRequiredParams();
    let frameProductConfiguration = new FrameProductCollection();

    try {
      frameProductConfiguration = await frameProductConfiguration.filterByParams(params);
      return frameProductConfiguration.first();
    } catch (err) {
      Logger.error('FrameCreateController.getInitialConfiguration', {
        error: err
      });
      return null;
    }
  }

  async getUserConfigurationFileList(configurationId, fileType) {
    return await new ProfessionalUserConfigurationFileCollection().filterByParams({ //eslint-disable-line
      configurationId: configurationId,
      fileType: fileType
    });
  }

  async getFrameDatasheet(url) {
    return await new HomeDecorDatasheet({ datasheetUrl: url }).fetch({
      data: {
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      },
      headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    });
  }

  /* extract (if possible) the default value from an item collection
   * and set it into configuration
   */
  setDefaultValueByItems(items) {
    let defaultValue = null;
    let isValidOption = false;
    let selected = this.configuration[items.model.modelName];
    /* se nella collection c'è bypassDefault non impostare il default */
    if (items.length === 1 && !items.bypassDefault) {
      defaultValue = items.first().id;
    } else {
      items.each(function (item, $selected, $isValidOption, $defaultValue) {  //eslint-disable-line
        isValidOption = isValidOption || item.id === selected;
        if (item.isDefault) {
          defaultValue = item.id;
        }
      });
    }

    /* avoid update of disabled widgets */
    let disabledWidgets = this.getdisabledWidgets();
    let widgetDisabled = disabledWidgets.indexOf(items.model.modelName) > -1;
    if (widgetDisabled) {
      defaultValue = this.product.productConfiguration[items.model.modelName];
    }

    if (!defaultValue && items.getFirstDefault) {
      defaultValue = items.first().id;
    }

    if (!isValidOption && defaultValue) {
      this.configuration[items.model.modelName] = defaultValue;
    }
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product, // informazioni sul prodotto
      configuration: this.configuration, // stato dei filtri
      filters: this.filters, // collection dei filtri
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      user: this.user,
      customerUser: this.customerUser,
      loadingCollection: this.loadingCollection,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      loadingPreview: this.loadingPreview,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      updatePrice: this.updatePrice,
      unitOfMeasure: this.unitOfMeasure,
      previewSvg: this.previewSvg,
      productSvg: this.productSvg
    });
  }

  get view() {
    return FrameContentView;
  }

  async initContentProps() {
    super.initContentProps();

    //initialize content view properties
    this.product = {
      collection: new FrameProductCollection(),
      productItem: {},
      previewUrl: null,
      productConfiguration: new FrameConfiguration(),
      price: {},
      showWidget: false,
      showWidgetPrice: this.customerUserIsAuthorized
    };
  }

  async loadInitialConfiguration() {
    let configuration = {};
    let currentConfiguration;
    let configurationId = this.request.query.configurationId;

    if (configurationId) {
      Logger.info('Frame ConfigurationId', configurationId);
      currentConfiguration = new FrameConfiguration({ id: configurationId });

      try {
        await currentConfiguration.fetch({
          data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE },
          headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
        });
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
            )
          );
          navigateTo(this.request, this.response, 'frame:new', {}, true, null);
          return false;
        } else {
          Logger.error('FrameController.loadInitialConfiguration - FrameConfiguration FETCH', {
            error: err,
            id: configurationId
          });
          return configuration;
        }
      }

      this.product.productConfiguration = currentConfiguration;
      Logger.info('restored configuration', currentConfiguration.toJSON());

      configuration = {
        frameColor: currentConfiguration.frameColor,
        format: currentConfiguration.format,
        orientation: currentConfiguration.orientation,
        paper: currentConfiguration.paper,
        quantity: currentConfiguration.quantity,
        category: currentConfiguration.category,
        packagingId: currentConfiguration.packagingId,
        frameMaterial: currentConfiguration.frameMaterial,
        commercialProduct: currentConfiguration.commercialProduct,
        photoSupport: currentConfiguration.photoSupport,
        sideThickness: currentConfiguration.sideThickness,
        spacer: currentConfiguration.spacer,
        supportType: currentConfiguration.supportType,
        paperLamination: currentConfiguration.paperLamination
      };

      this.configuration = configuration;

      await this.initEditorSvgs();
    } else {
      // if configurationId is not present redirect to albumepoca
      this.redirectToHomepage();
      return null;
    }
  }

  async initEditorSvgs() {
    let productConfiguration = await this.getProductConfiguration();

    let dataSheetUrl = productConfiguration.frontDatasheet.substring(
      1,
      productConfiguration.frontDatasheet.length
    );

    this.dataSheet = await this.getFrameDatasheet(dataSheetUrl);

    try {
      [this.previewSvg, this.productSvg] = await Promise.all([
        this.getUserConfigurationFileList(
          this.product.productConfiguration.id,
          this.dataSheet.previewSvgFileType
        ),
        this.getUserConfigurationFileList(
          this.product.productConfiguration.id,
          this.dataSheet.svgFileType
        )
      ]);
      this.previewSvg = this.previewSvg.first();
      this.productSvg = this.productSvg.first();
    } catch (err) {
      Logger.error('initEditorSvgs - unable to fetch userConfigurationFiles', {
        error: err,
        configuration: this.product.productConfiguration
      });
    }
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let initialConfiguration = await this.loadInitialConfiguration();

      this.stopInitialRendering();

      if (initialConfiguration !== null) {
        await this.reloadView();
      }

      EventTracker.log(
        this.customerUser,
        'configurator_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async reloadView(getPrice) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      await this.loadCollection(new FrameOrientationTypesCollection());

      await this.loadCollection(new FrameFormatTypesCollection());

      await this.loadCollection(new PhotoSupportTypesCollection());

      await Promise.all([
        this.loadCollection(new FramePaperTypesCollection()),
        this.loadCollection(new FrameColorTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new SideThicknessTypesCollection()),
        this.loadCollection(new SupportTypesCollection()),
        this.loadCollection(new PaperLaminationTypesCollection()),
        this.loadCollection(new SpacerTypesCollection())
      ]);
    } catch (err) {
      Logger.error('FrameController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }
    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice = true) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('FrameController.getPriceForCurrentConfiguration', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        await this.loadProduct();
        if (getPrice) {
          await this.loadPrice();
        }
      } catch (err) {
        Logger.error('FrameController.getPriceForCurrentConfiguration', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  getConfigurationRequiredParams() {
    return {
      frameColor: this.configuration.frameColor,
      format: this.configuration.format,
      orientation: this.configuration.orientation,
      paper: this.configuration.paper,
      category: this.configuration.category,
      softwareCode: 'PROFESSIONAL',
      commercialProduct: this.product.productConfiguration.commercialProduct,
      paperLamination: this.configuration.paperLamination,
      supportType: this.configuration.supportType,
      photoSupport: this.configuration.photoSupport,
      sideThickness: this.configuration.sideThickness,
      spacer: this.configuration.spacer
    };
  }

  getPriceRequiredParams() {
    return {
      productCode: this.product.productItem.code,
      quantity: this.configuration.quantity,
      softwareCode: 'PROFESSIONAL'
    };
  }

  async loadProduct() {
    let requiredParams = this.getConfigurationRequiredParams();
    let product = await super.getProduct(new FrameProductCollection(), requiredParams);
    if (product) {
      this.setConfigurationProduct(product);
    }
  }

  async setConfigurationProduct(productItem) {
    super.setConfigurationProduct(productItem);
    this.render(this.context);
  }

  async loadPrice() {
    let requiredParams = this.getPriceRequiredParams();
    requiredParams = Object.assign(requiredParams, {
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
    });
    let price = await this.getPrice(new FramePriceCollection(), requiredParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  async getPreparedConfiguration(configuration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!configuration) {
      configuration = this.configuration;
    }

    configuration.quantity = this.configuration.quantity;
    configuration.category = this.configuration.category;
    configuration.frameColor = this.configuration.frameColor;
    configuration.format = this.configuration.format;
    configuration.orientation = this.configuration.orientation;
    configuration.paper = this.configuration.paper;

    //need to refresh all params
    let productConfiguration = await this.getProductConfiguration();

    configuration.shopCode = shopcode;
    configuration.frameMaterial = productConfiguration.frameMaterialType;
    configuration.frameThickness = productConfiguration.frameThickness;
    configuration.frameType = productConfiguration.frameType;
    configuration.kitType = productConfiguration.kitType;
    configuration.mosaicType = productConfiguration.mosaicType;
    configuration.packaging = productConfiguration.packagingCode;
    configuration.paperLamination = productConfiguration.paperLaminationType;
    configuration.passepartout = productConfiguration.passepartoutCode;
    configuration.passepartoutColor = productConfiguration.passepartoutColorType;
    configuration.photoMaterial = productConfiguration.photoMaterialType;
    configuration.sideColor = productConfiguration.sideColor;
    configuration.supportColor = productConfiguration.supportColorType;
    configuration.supportMaterial = productConfiguration.supportMaterialType;
    configuration.supportType = productConfiguration.supportType;
    configuration.commercialProduct = productConfiguration.commercialProduct;
    configuration.photoSupport = productConfiguration.photoSupport;
    configuration.sideThickness = productConfiguration.sideThickness;
    configuration.accessory = productConfiguration.accessory;

    return configuration;
  }

  async saveProductConfiguration() {
    try {
      let currentConfiguration = await this.getPreparedConfiguration();
      let productConfiguration = this.product.productConfiguration;

      let frameConfiguration = await productConfiguration.save(currentConfiguration, {
        data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE },
        headers: { 'x-Software-Code': conf.settings.PROFESSIONAL_SOFTWARE_CODE }
      });
      Logger.info('Frame Configuration id: ', this.product.productConfiguration.id);
      return frameConfiguration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('FrameController.saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      return undefined;
    }
  }

  async getFramePreOrder(configurationId) {
    return await getPreOrder(configurationId, conf.settings.FRAME_PRODUCT_TYPE);
  }

  async getFramePackagingPreOrderId(packagingConfigurationId) {
    let preorder = await getPreOrder(
      packagingConfigurationId,
      conf.settings.FRAME_PACKAGING_PRODUCT_TYPE
    );
    if (preorder) {
      return preorder.id;
    } else {
      return null;
    }
  }

  async initPreOrders(configurationId, packagingConfigurationId) {
    let professionalProjectId = null;
    let framePreOrder;
    let framePreOrderId = null;
    let framePackagingPreOrderId;

    [framePreOrder, framePackagingPreOrderId] = await Promise.all([
      this.getFramePreOrder(configurationId),
      this.getFramePackagingPreOrderId(packagingConfigurationId)
    ]);

    if (framePreOrder) {
      framePreOrderId = framePreOrder.id;
      professionalProjectId = framePreOrder.projectId;
    }

    return [professionalProjectId, framePreOrderId, framePackagingPreOrderId];
  }

  async savePreOrders() {
    let frameConfiguration = this.product.productConfiguration;
    let framePackagingConfiguration = {
      id: frameConfiguration.packagingId,
      productType: conf.settings.FRAME_PACKAGING_PRODUCT_TYPE
    };
    let timestamp = new Date().getTime();

    /** check pre-orders **/
    if (!(this.request.query.configurationId && this.request.query.packagingConfigurationId)) {
      return [false, new Error('Configurations not found')];
    }

    [this.projectId, this.preOrderId, this.packagingPreOrderId] = await this.initPreOrders(
      frameConfiguration.numericId,
      this.request.query.packagingConfigurationId
    );

    if (this.projectId && this.preOrderId && this.packagingPreOrderId) {
      return [true, undefined];
    }

    /** create pre-orders **/
    try {
      let framePreorder = await createProfessionalPreOrder(
        this.customerUser,
        frameConfiguration,
        null,
        1,
        frameConfiguration.id
      );

      this.preOrderId = framePreorder.id;
      this.projectId = framePreorder.projectId;
      let framePackagingPreorder = await createProfessionalPreOrder(
        this.customerUser,
        framePackagingConfiguration,
        this.projectId
      );

      this.packagingPreOrderId = framePackagingPreorder.id;

      EventTracker.log(this.customerUser, 'bucket_add_success', {
        product_category: productCategoryInterface(this.product.productConfiguration.productType),
        project_id: this.projectId
      });

      return [true, undefined];
    } catch (err) {
      Logger.error('Frame.savePreOrders - createPreOrder', {
        error: err,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser,
        frameConfiguration: frameConfiguration,
        packagingConfiguration: framePackagingConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: createPreOrder - code: ' + timestamp,
        this.i18n.gettext('Chiudi'),
        null,
        null
      );
      return [false, err];
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    if (await this.checkWidgetErrors()) {
      let frameConfiguration = await this.saveProductConfiguration();

      if (!this.isUserLoggedIn()) {
        this.redirectToLoginPage();
        return null;
      }

      if (this.customerUser && !this.customerUser.shopCode) {
        this.stopLoadingPage();
        GuiErrors.modalError(
          this.i18n.gettext('User not authorized to add product to cart'),
          'Error detail: shopCode null',
          this.i18n.gettext('Chiudi'),
          null,
          null
        );
        return false;
      }

      if (frameConfiguration) {
        let [preOrderResult, error] = await this.savePreOrders();

        if (preOrderResult) {
          // update project name
          try {
            let projectName = await getProjectName(conf.settings.FRAME_PRODUCT_TYPE);
            let project = await new Project({ id: this.projectId }).fetch();
            project.name = projectName
              ? projectName
              : `${this.i18n.gettext('Project')} ` + this.getDateNow();
            await project.save();
          } catch (err) {
            const timestamp = new Date().getTime();
            Logger.error('FrameController.savePreOrders - project.save failed', {
              error: err,
              errorCode: timestamp,
              projectId: this.projectId
            });
          }

          this.goToEditor();
        } else {
          EventTracker.log(this.customerUser, 'bucket_add_error', {
            product_category: productCategoryInterface(
              this.product.productConfiguration.productType
            ),
            error_code: String(error)
          });
        }
      }
    }
    this.stopLoadingPage();
  }

  getAeVeloceApplicationUrl() {
    return conf.settings.EDITOR_VELOCE_HOMEDECOR_URL;
  }

  goToEditor() {
    let nextState = null;

    this.startLoadingPage();

    nextState = this.getAeVeloceApplicationUrl();
    window.location.href = `${nextState}?configurationId=${this.request.query.configurationId}&projectId=${this.projectId}&language=${this.request.language}`;

    this.stopLoadingPage();
  }

  getdisabledWidgets() {
    let disabledList = [];
    if (this.productSvg) {
      disabledList = ['orientation'];
    }
    return disabledList;
  }

  async resetEditorProject() {
    try {
      await Promise.all([this.productSvg.destroy(), this.previewSvg.destroy()]);
    } catch (err) {
      Logger.error('Frame.resetEditorProject - remove svgs preview and production', {
        error: err,
        user: this.user,
        productSvg: this.productSvg,
        previewSvg: this.previewSvg
      });
    }
  }

  hasEditorProject() {
    if (this.productSvg) {
      return true;
    }
    return false;
  }
}
