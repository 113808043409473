import { BaseSelectionHomeDecorWidgetWithImages } from '../../core/components/baseSelectionHomeDecorWidgetWithImages';

export class SideThicknessTypesWidget extends BaseSelectionHomeDecorWidgetWithImages {
  get name() {
    return 'sideThickness';
  }

  get label() {
    return this.i18n.gettext('spessore');
  }

  get showWidget() {
    this._showWidget =
      (this.props.items && this.props.items.length > 1) ||
      (this.props.items && this.props.items.length === 1 && this.props.items.models[0].id !== 'NO');
    return this._showWidget;
  }
}
