import { BaseSelectionWidgetFourColumns } from '../../core/components/baseSelectionWidgetFourColumns';

export class OrientationTypesWidget extends BaseSelectionWidgetFourColumns {
  get name() {
    return 'orientation';
  }

  get label() {
    return this.i18n.gettext('orientamento');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    //return this.props.delegate.hasCoverEditorWidget() && this.props.delegate.isCoverEditorProjectValid();
    return false;
  }

  async onModalConfirmClick() {
    //this.props.delegate.resetPhotographicBook();
    return false;
  }
}
