import React from 'react';
import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';
import { AeImage } from '../../core/components/AeImage';

export class FrameColorTypesWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'frameColor';
  }

  get label() {
    return this.i18n.gettext('color');
  }

  /*render all the avalilable items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-6 padding-color"
            onMouseOver={this.handleMouseOver.bind(this, item)}
            onMouseOut={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span className="colors__item__image">
                <AeImage src={item.previews[0] ? item.previews[0].url : null} />
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  /*render only the selected item if available*/
  renderSelectedItem() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.id === this.props.selected) {
          return (
            <div key={item.id} className="col-xs-6 col-sm-10 col-md-10 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className="colors__item__image">
                  <AeImage src={item.previews[0] ? item.previews[0].url : null} />
                </span>
                <span className="colors__item__text">
                  {item.name}
                  <br />
                  {item.description}
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }

  get showWidget() {
    this._showWidget =
      (this.props.items && this.props.items.length > 1) ||
      (this.props.items && this.props.items.length === 1 && this.props.items.models[0].id !== 'NO');
    return this._showWidget;
  }
}
