import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperLaminationTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paperLamination';
  }

  get label() {
    return this.i18n.gettext('plastificazione home decor');
  }

  get showWidget() {
    this._showWidget = this.props.items && this.props.items.length > 1;
    return this._showWidget;
  }
}
