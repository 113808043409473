import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paper';
  }

  get label() {
    return this.i18n.gettext('carta home decor');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    return false;
  }

  async onModalConfirmClick() {
    return false;
  }

  get showWidget() {
    this._showWidget =
      (this.props.items && this.props.items.length > 1) ||
      (this.props.items && this.props.items.length === 1 && this.props.items.models[0].id !== 'NO');
    return this._showWidget;
  }
}
