import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  FrameController,
  FrameCreateController,
  FrameRestoreProjectController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('frame:main', FrameController),
      'new/': routing.url('frame:new', FrameCreateController),
      new: routing.url('frame:new', FrameCreateController),
      'edit/:projectId:': routing.url('frame:restoreProject', FrameRestoreProjectController)
    };
  }
}
