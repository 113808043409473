import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { ProjectNameWidget } from '../core/components/projectNameWidget';
import { FramePreview } from './components/framePreview';
import { FiltersTitle } from '../core/components/filtersTitle';

import { OrientationTypesWidget } from './components/orientationTypesWidget';
import { FormatTypesWidget } from './components/formatTypesWidget';
import { FrameColorTypesWidget } from './components/frameColorTypesWidget';
import { PaperTypesWidget } from './components/paperTypesWidget';
import { PaperLaminationTypesWidget } from './components/paperLaminationTypesWidget';
import { PhotoSupportTypesWidget } from './components/photoSupportTypesWidget';
import { SpacerTypesWidget } from './components/spacerTypesWidget';
import { SideThicknessTypesWidget } from './components/sideThicknessTypesWidget';
import { SupportTypesWidget } from './components/supportTypesWidget';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.framePrices || this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row product-preview album-product-preview">
        <div className="col-xs-12">
          <FramePreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle key="FiltersTitle" title={this.i18n.gettext('Personalizza il tuo frame')} />,

      <ProjectNameWidget
        key="ProjectNameWidget"
        delegate={this.delegate}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
      />,

      <PhotoSupportTypesWidget
        key="PhotoSupportTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.photoSupportTypes}
        selected={this.props.configuration.photoSupport}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <OrientationTypesWidget
        key="OrientationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.orientations}
        selected={this.props.configuration.orientation}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FormatTypesWidget
        key="FormatTypesWidget"
        delegate={this.delegate}
        unitOfMeasure={this.delegate.unitOfMeasure}
        items={this.props.filters.formats}
        selected={this.props.configuration.format}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SideThicknessTypesWidget
        key="SideThicknessTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.sideThicknessTypes}
        selected={this.props.configuration.sideThickness}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperTypesWidget
        key="PaperTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.papers}
        selected={this.props.configuration.paper}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperLaminationTypesWidget
        key="PaperLaminationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperLaminationTypes}
        selected={this.props.configuration.paperLamination}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FrameColorTypesWidget
        key="FrameColorTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.frameColors}
        selected={this.props.configuration.frameColor}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SpacerTypesWidget
        key="SpacerTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.spacerTypes}
        selected={this.props.configuration.spacer}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SupportTypesWidget
        key="SupportTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.supportTypes}
        selected={this.props.configuration.supportType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class FrameContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
  }
}
